.bmrf {
  width: 30%;
  height: 100%;
  margin-left: 37px;
  margin-top: -11rem;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.bmrf-heading > p {
  height: 52px;
  background: #66a3a6;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
  color: white;
  font-family: "Nunito";
  border-radius: 10px 10px 0px 0px;
}

.bmrf-form {
  height: 92%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
}

.formFieldContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 4px;
  overflow: hidden auto;
  font-family: "Nunito";
}

.bmrf-form input {
  width: 100%;
  height: 45px;
  font-family: "Nunito";
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.bmrf-form input:focus {
  outline: none;
}

.row1,
.row2,
.row3,
.row4,
.row5 {
  width: 75% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 29px;
  position: relative;
}
.row1 > div,
.row2 > div,
.row3 > div,
.row4 > div,
.row5 > div,
.row5 > textarea {
  width: 100% !important;
}

/* .row3 > input {
  width: 100%;
} */

.row4 {
  width: 90%;
  min-height: 51px;
  border: 1px solid;
  border-radius: 5px;
}

.row5 > textarea {
  width: 100%;
  height: 118px;
  border-radius: 2px;
  font-size: 16px;
  border: 0.5px solid black;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito";
  margin-top: 6px;
}

.row5 > textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito";
  font-weight: 400;
  text-transform: capitalize;
}

input[type="time"] {
  position: relative;
}
.row1 > div,
.row2 > div,
.row3 > div,
.row4 > div,
.row5 > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.form-sbt-btn > button {
  width: 150px;
  height: 50px;
  background: #66a3a6;
  border: none;
  border-radius: 10px;
  font-size: 24px;
  font-family: "Nunito";
  color: white;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .bmrf {
    /* height: fit-content !important; */
    margin-top: 0;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .bmrf-heading > p {
    font-size: 22px;
  }
  .row5 > textarea {
    height: 85% !important;
    min-height: 10px;
  }
  .bmrf-form input {
    height: 100%;
    box-sizing: border-box;
    padding-left: 4px;
  }

  .formFieldContainer {
    height: 100%;
    gap: 2%;
  }
  .bmrf {
    margin-top: -7rem !important;
    margin-left: unset !important;
    width: 34%;
    height: 80vh;
  }
  .form-sbt-btn > button {
    width: 100%;
    height: max-content;
    padding: 0.35rem 1rem;
    font-size: 18px;
  }
  .row5 {
    height: 8rem;
  }
  .bmrf-form {
    margin: unset !important;
    padding-top: 1rem;
    height: 90%;
    justify-content: space-around;
    box-sizing: border-box;
  }
  .row1,
  .row2,
  .row3,
  .row4 {
    height: 13%;
  }
  .row1 > div,
  .row2 > div,
  .row3 > div,
  .row4 > div,
  .row5 > div {
    height: 100% !important;
  }
  .makebold {
    font-size: 16px;
  }
}
