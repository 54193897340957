.EventViewWrapper {
  height: 100%;
}

.EventNavbarSection {
  display: flex;
  justify-content: flex-end;
  padding-right: 10rem;
  padding-top: 4rem;
}

.createNewButton {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  background-color: #66a3a6;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  border-radius: 5px;
  border: none;
  align-items: center;
}

.event_form_field {
  width: 85.5%;
  height: 80px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.event_form_input_field {
  height: 60px;
  width: 45%;
  border-radius: 4px;
  border: 1px solid #000000;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  color: #000000;
}

.event_form_input_field:focus {
  outline: none;
}

.event_form_input_field::placeholder {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.form_input_fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 51%;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}

.create_event_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 3rem 1rem;
}

.event_publish_button {
  width: 198px;
  height: 55px;
  top: 783px;
  left: 862px;
  gap: 0px;
  border-radius: 5px;
  border: none;
  background-color: #66a3a6;
  cursor: pointer;
  color: #ffffff;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
}

.image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 175px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  /* Semi-transparent black */
  pointer-events: none;
  /* Allows clicks to pass through the overlay */
}

.events {
  /* height: 77.8vh; */
  width: 100%;
  cursor: pointer;
  padding-left: 2.75rem;
  box-sizing: border-box;
}

.no_events {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-family: Nunito;
  font-size: 32px;
  font-weight: 300;
  line-height: 67.5px;
  text-align: center;
}

.event_card {
  width: 22rem;
  border: 1px solid;
  height: 19rem;
  border-radius: 10px;
  position: relative;
  background-color: #ffffff;
}

.events_image_container {
  width: 100%;
  height: 52%;
}

.event_image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.event_name {
  position: absolute;
  left: 10%;
  top: 50%;
  font-family: Nunito;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #ffffff;
}

.eventDetailsWrapper {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.card_event_name {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #000000;
}

.card_event_name>span {
  font-weight: 400;
}

.card_event_date,
.card_event_time,
.card_event_capacity {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  line-height: 22.5px;
  text-align: left;
  color: #000000;
}

.card_event_date>span,
.card_event_time>span,
.card_event_capacity>span {
  font-weight: 400;
}

.event_card_details {
  padding-top: 0.25rem;
  padding-left: 1rem;
}

.card_view {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 1.5rem;
  gap: 0.25rem;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
}

.dialog_event_name {
  color: #ffffff;
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-family: Nunito;
  font-size: 32px;
  font-weight: 400;
  line-height: 33.97px;
  text-align: center;
}

.event_details_card {
  height: 20em;
  width: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: #ffffff;
}

.event_details_cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7rem;
}

.event_detail_name {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
}

.event_detail_count {
  font-family: Nunito;
  font-size: 72px;
  font-weight: 400;
  line-height: 206.25px;
  text-align: center;
}

.event_details_container {
  width: 95%;
  background-color: #f4f4f4;
  display: flex;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0rem;
  gap: 2.5rem;
}

.event_details_header {
  font-family: Nunito;
  font-size: 32px;
  font-weight: 400;
  line-height: 43.75px;
  text-align: center;
}

.event_capacity {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 400;
  line-height: 43.75px;
  text-align: center;
}

.event_cards_container {
  padding-top: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  gap: 3rem;
  height: 100%;
  justify-content: flex-start;
}

.dialog_container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 20px;
}

.events_image_container {
  width: 100%;
  position: relative;
}

.events_image_container svg {
  position: absolute;
  right: 1%;
  top: 6%;
}

.events_image_container>div {
  position: absolute;
  z-index: 100;
  background-color: red;
  right: 5%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  top: 10%;
  cursor: pointer;
}

.events_image_container>img {
  width: 100%;
  height: 22vh;
  object-fit: cover;
}

.event_dialog>.p-dialog-header>.p-dialog-header-icons {
  display: none;
}

.p-dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dialog.css */
.dialog-overlay {
  position: absolute;
  /* top: -18%;
  left: -8%;
  right: -1%;
  bottom: -20%; */
  width: 99.2vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.dialog {
  background: white;
  border-radius: 20px;
  width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dialog-header h2 {
  margin: 0;
}

.dialog-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.dialog-body {
  margin-bottom: 20px;
}

.dialog-footer {
  text-align: right;
}

.booking_confirmed {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.event_booked_name {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 400;
  line-height: 22.12px;
  text-align: left;
}

.event_booked_date {
  font-family: Nunito;
  font-size: 22px;
  font-weight: 300;
  line-height: 22.12px;
  text-align: left;
}

.event_booked_time {
  display: flex;
  gap: 0.55rem;
  font-family: Nunito;
  font-size: 22px;
  font-weight: 300;
  line-height: 22.12px;
  text-align: center;
}

.booked_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #efefef;
  padding: 1rem;
}

.qr_code {
  width: 13rem;
  height: 13rem;
}

.qr_code>img {
  width: 100%;
  height: 100%;
}

.booked_details_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 3rem;
}

.event_attendence_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event_attendence_form_wrapper {
  box-shadow: 0px 4px 4px 0px #0000000d;
  border-radius: 4px;
  height: 28rem;
  width: 30rem;
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.event_attendence_form_field {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.event_attendence_input_container {
  position: relative;
  width: 100%;
}

.event_attendence_input_container>input {
  height: 2rem;
  width: 100%;
  border-radius: 4px;
  font-family: Nunito;
  padding-left: 6px;
}

.event_attendence_input_container>img {
  position: absolute;
  width: 25px;
  height: 20px;
  top: 25%;
  right: 10px;
}

.event_attendence_form_field>span {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 2px;
}

.event_attendence_submit {
  background-color: #66a3a6;
  padding: 8px 30px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: Nunito;
}

.message_icon {
  width: 16px;
  height: 16px;
}

.event_attendence_information {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.event_attendence_information>span {
  font-family: Nunito;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.event_attendence_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.event_attendence_header>svg {
  width: 9rem;
}

.event_attendence_event_name {
  font-family: Nunito;
  font-size: 38px;
  font-weight: 400;
  line-height: 33.97px;
  text-align: center;
  color: #66a3a6;
}

.event_attendence_eventdate_and_time {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  line-height: 18.17px;
  text-align: center;
}

.paginationNumbers.user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8%;
  gap: 1%;
  margin-top: 1.5vw;
}

.paginationNumbers.user>button.active {
  background-color: #66a3a6;
  color: white;
}

.event_image_container {
  height: 52%;
}

.card_view>span {
  font-weight: 700;
  color: #66a3a6;
}

.card_view>svg path {
  fill: #66a3a6 !important;
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .events {
    height: unset;
    padding-left: 6rem;
  }

  .events-pagination {
    height: 85vh;
    overflow: scroll auto;
  }

  .event_card {
    width: 32%;
    height: 16rem;
    padding-bottom: 1rem;
  }

  .event_image_container {
    height: 45%;
  }

  .card_view {
    font-size: 16px;
  }

  .event_cards_container {
    gap: 2rem;
  }

  .event_card_details {
    padding-left: unset;
    padding-top: unset;
    padding: 0.5rem;
  }
}

.paginationNumbers>button {
  height: 25px;
  width: 25px;
  border-radius: 4px;
}

.events-pagination {
  height: 76vh;
  width: 93vw;
  position: relative;
}

.active {
  margin: 0;
  width: auto;
  background-color: #66a3a6;
  color: #ffffff;
}

.MuiDialogActions-root>button {
  display: none;
}

.css-1wppq00-MuiPaper-root-MuiDialog-paper {
  max-width: 80%;
}

@media only screen and (max-width: 1023px) and (min-width: 769px) {
  .events-pagination {
    height: auto;
    max-height: 92vh;
  }

  .event_cards_container {
    padding-top: 5vw;
  }

  .event_card {
    width: 36vw;
    border: 1px solid;
    height: 34vw;
  }

  .events {
    padding-left: 4vw;
    height: auto;
  }

  .card_event_name {
    font-size: 17px;
  }

  .card_event_date,
  .card_event_time,
  .card_event_capacity {
    font-family: Nunito;
    font-size: 11px;
  }

  .card_view {
    padding-bottom: 2vw;
    font-size: 14px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 328px) {
  .events {
    padding: 0;
  }

  .event_cards_container {
    padding-top: 2%;
    display: flex;
    justify-content: center;
  }

  .event_card {
    width: 84%;
    border: 1px solid;
    height: auto;
    border-radius: 10px;
    position: relative;
    background-color: #ffffff;
  }

  .card_event_name {
    font-size: 18px;
  }

  .card_view {
    font-size: 14px;
    margin-bottom: 2%;
  }

  .event_image {
    display: block;
    width: 100%;
    height: 113px;
    border-radius: 10px;
    object-fit: cover;
  }

  .paginationNumbers.user {
    display: none;
  }

  .event_form_input_field {
    height: 10%;
    width: 85%;
    border-radius: 4px;
    border: 1px solid #000000;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
    padding: 4% 0% 2% 5%;
  }

  .event_publish_button {

    border: none;
    background-color: #66a3a6;
    cursor: pointer;
    color: #ffffff;
    font-family: Nunito;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    width: 120%;
    height: 35px;
  }
}