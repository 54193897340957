.QbLeftRightStyles {
  height: 100%;
  width: 101%;
}

.QbRightSection {
  gap: 2rem;
}

.QbleftSection > div {
  margin-top: 2em;
  box-sizing: border-box;
  display: flex;
  gap: 1.5em;
  height: 100%;
}

.QbleftSection > img {
  top: 65%;
  left: 4%;
  width: 25%;
}

.QbleftSection > div > img:first-child {
  width: 9em;
  height: 9em;
}

.statusImage {
  width: 83% !important;
}

.statusImage > svg {
  width: 100% !important;
}

.QbleftSection > div > .QbLogo {
  width: 30% !important;
  height: 40%;
}

.QbleftSection > div > span {
  color: white;
}

.qb-heading {
  display: block;
  padding: 4% 0 0 8%;
  font-size: 44px;
  font-family: Poppins;
  color: white;
  width: 100%;
}

.LeftSectionHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leftSection.QbleftSection {
  background-color: #66a3a6;
}

.QbLeftSectionInnerContainer > svg {
  width: 30% !important;
}

.rightSection.QbRightSection {
  width: 50%;
  overflow: auto;
  max-height: 100%;
}
/* .tempSetupStyle{
  padding-top: 9%;
} */

.logo-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Nunito;
}

.data > h1 {
  font-family: Nunito;
  font-weight: 500;
  margin: 0;
}

.statusDescription {
  font-size: 16px;
  font-family: "Nunito";
  display: flex;
  gap: 10rem;
  width: 83% !important;
}

.quick-book-dialog  {
  display: flex;
  height: 100%;
}

.statusActive {
  color: #66a3a6;
}

.statusInactive {
  color: #6e6e6e;
}

.data > span {
  font-weight: 100;
  color: #000000;
}

.LeftSectionHeading > span {
  color: white;
  font: Nunito;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.LeftSectionHeading > h1 {
  margin-bottom: 4%;
  font: Nunito;
  font-weight: 500;
  font-size: 2em;
  text-align: center;
}

.QbFormProgress {
  display: flex;
  gap: 1em;
  height: 9em;
}

.QbFormProgress > div {
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000000;
  font-family: Nunito;
}

.QbFormProgress > div > p {
  margin: 0px;
}

.QbButtonsWrapper {
  width: 76%;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.qb2btn {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 4%;
}

.qb3btn {
  width: 83%;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.qb3btn > div {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 3%;
  margin-top: 6px;
}

@media only screen and (max-width: 1440px) {
  .QbButtonsWrapper {
    margin-bottom: 30px;
  }

  .inputContainer > div {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .QbleftSection {
    height: 50%;
  }

  .QbRightSection {
    gap: 3rem;
  }

  .qb-heading {
    display: block;
    padding: 4% 4% 2% 4%;
    font-size: 28px;
    font-family: Nunito;
    color: white;
    text-align: center;
  }

  .QbleftSection > img {
    display: none;
  }

  .QbFormProgress {
    flex-direction: column;
  }

  .qb3btn > div {
    justify-content: center;
    gap: 13%;
  }

  .QbFormProgress > div {
    flex-direction: row;
  }

  .QbFormProgress > svg {
    width: 100%;
  }

  .QbFormProgress > div > p {
    width: 30%;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .heading > img {
    width: 50%;
    height: 1em;
  }

  .basicDeatilsForm {
    width: 90%;
    column-gap: 20px;
    row-gap: 0px;
  }

  .QbButtonsWrapper {
    margin: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .QbLeftSectionInnerContainer {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    gap: 13px !important;
  }

  .rightSection.QbRightSection {
    width: 100%;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .qb2btn {
    justify-content: center;
    gap: 11%;
  }

  .logo {
    display: none;
  }

  .data {
    margin-top: 10px;
  }

  .data > h1 {
    font-size: 3vw;
  }

  .data > span {
    font-weight: 500;
  }

  .statusImage {
    width: 83% !important;
  }

  .statusImage > svg {
    width: 100% !important;
  }

  .statusDescription {
    gap: 5rem;
  }

  .inputContainer > div {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .basicDeatilsForm {
    grid-template-columns: auto;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .registerModal > form {
    /* background-image: url("../assets/svg/incuhub-bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  }
  .leftSection.QbleftSection {
    background-color: unset;
  }
  .img-container {
    display: none;
  }
  .QbLeftSectionInnerContainer,
  .QbleftSection {
    display: none !important;
  }
  .rightSection.QbRightSection {
    height: 100vh !important;
  }
  .data > h1 {
    font-size: 24px !important;
  }
  .data {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .registerModal > form {
    width: 90% !important;
  }
  .registerBtn > button {
    width: max-content !important;
    /* padding: unset; */
    font-size: 18px !important;
    padding: 0rem 1rem;
  }
  .quick-book-dialog {
    height: 80vh !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .qb-heading {
    font-size: 34px;
  }
  .statusDescription {
    width: 100% !important;
    gap: 1rem;
    justify-content: space-around;
  }
  .QbLeftRightStyles > .rightSection > .logo-data > .logo > img {
    height: unset;
    width: 100% !important;
  }
  .quick-book-dialog {
    display: flex;
  }
}
