.login-container {
  display: flex;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
}

.desktop-container {
  width: 100%;
  height: 100%;
  display: flex;
  background: #a5ccce;
  padding: 88px 109px 88px 100px;
}

.loginbtns:disabled {
  cursor: not-allowed; /* Changes the cursor to indicate the button is not clickable */
  background-color: #d3d3d3; /* Light gray background to indicate a disabled state */
  color: #a9a9a9; /* Gray text to complement the background */
  border: 1px solid #c0c0c0; /* Subtle border to maintain structure */
  opacity: 0.7; 
}

.desktop-left-section {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  border-radius: 30px 0px 0px 30px;
}

.desktop-left-contents {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-section {
  width: 50%;
  height: 100vh;
}

.right-section {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: end;
  border-radius: 0px 30px 30px 0px;
  background: #66a3a6;
}

.home-heading {
  display: block;
  padding: 4% 0 0 4%;
  font-size: 44px;
  font-family: Poppins;
  color: white;
}

.home-desc {
  font-size: 24px;
  padding: 4% 0 0 4%;
  font-size: 24px;
  font-family: Poppins;
  color: white;
}

.login-logo {
  width: 8rem;
}

.desktop-right-contents {
  height: 86%;
  display: flex;
  flex-direction: column;
}

.login-picture {
  width: 100%;
  height: 100%;
}

.login-heading {
  font-family: "Nunito", sans-serif;
  font-weight: 100;
  color: black;
  font-size: 50px;
  margin-top: auto;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.login-desc {
  display: flex;
  justify-content: center;
  font-weight: 300;
  font-family: "Nunito", sans-serif;
  display: flex;
  width: 77%;
  word-wrap: break-word;
  text-align: center;
  margin-bottom: 2em;
  text-transform: capitalize;
  font-size: 22px;
}

.btn-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.loginbtns {
  width: 63%;
  height: 3.9rem;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  color: rgba(34, 108, 112, 1);
  background: linear-gradient(
    90deg,
    rgba(101, 163, 166, 0.2) 10%,
    rgba(255, 255, 255, 0.5) 70%
  );
  border: 1px solid #65a3a6;
  border-radius: 10px;
  transition: background 0.3s ease, color 0.3s ease;
  font-weight: 600;
}

.home-image {
  width: 70%;
  height: 97%;
}

.img-container {
  height: 78%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loginbtns:hover {
  background-color: #66a3a6;
  color: white;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  /* Your CSS styles for larger tablets and small desktops */
  .right-section {
    width: 50%;
    /* height: 100vh; */
  }

  .left-section {
    width: 50%;
  }

  .left-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .right-contents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .login-logo {
    margin: 0;
    height: unset;
}
  .login-heading {
    margin-top: 14px;
  }

  .login-desc {
    width: 59%;
    margin: 0;
  }

  .loginbtns {
    cursor: pointer;
    width: 23%;
    font-size: 1.5vw;
    height: 4vw;
  }

  .btn-section {
    gap: 30px;
    width: 100%;
    padding-top: 3%;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .left-section {
    /* max-width: 100%;
    max-height: 100%; */
    width: 100%;
    height: 100%;
    background-color: #faf9f6;
  }

  .mobile-img-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  /* .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  } */

  .right-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }

  .login-heading {
    color: #65a3a6;
    text-align: center;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
  }

  .login-desc {
    color: #65a3a6;
    text-align: center;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    width: auto;
    margin-bottom: 1rem;
  }

  .loginbtns {
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
    border: 1px solid #65a3a6;
    border-radius: 10px;
    width: 220px;
    height: 47px;
    color: #65a3a6;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    padding-left: 3.25rem;
    gap: 6px;
  }

  .login-container {
    /* background-image: url(../../assets/png/LoginPicOp.png); */
    background-size: 100vw 100%;
    height: 100vh;
    background-repeat: no-repeat;
    /* padding: 1.5rem 0rem; */
  }

  .login-logo {
    width: unset;
    height: unset;
    margin: 0;
  }
  .loginbtns:hover {
    background-color: #66a3a6;
  }

  .btn-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .login-container {
    position: relative;
    background-color: #a5ccce;
  }

  .tab-container {
    width: 100vw;
    height: 88vh;
    display: flex;
    flex-direction: column-reverse;
    margin: 8% 5%;
    background: white;
    border-radius: 30px;
  }

  .left-section {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    background: rgba(0, 0, 0, 1);
  }

  .left-section > img {
    opacity: 0.5;
  }

  .login-logo {
    width: 9vw;
  }

  .login-heading {
    font-family: "Nunito", sans-serif;
    font-weight: 500;
    color: #000000;
    font-size: 4vw;
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;
  }

  .home-heading {
    display: block;
    padding: 4% 4% 2% 4%;
    font-size: 28px;
    font-family: Nunito;
    color: white;
    text-align: center;
  }

  .home-desc {
    padding: 0 4%;
    font-size: 15px;
    font-family: Poppins;
    color: white;
    text-align: center;
  }

  .img-container {
    height: 68%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .home-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .desktop-right-contents {
    height: 100%;
  }

  .right-section {
    height: 50%;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
  }
  .right-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
  }

  .desktop-left-section {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 0px 0px 30px 30px;
  }

  .desktop-left-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .login-desc {
    font-weight: 300;
    font-family: "Nunito", sans-serif;
    display: flex;
    width: 61%;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 3em;
    font-size: 2.5vw;
  }

  /* span::first-letter {
    text-transform: uppercase;
  } */

  .loginbtns {
    width: 179px;
    height: 54px;
    flex-shrink: 0;
    border-radius: 10px;
    text-align: center;
    font-family: Nunito;
    font-size: 21px;
    font-weight: normal;
    color: rgba(34, 108, 112, 1);
    background: linear-gradient(
      90deg,
      rgba(101, 163, 166, 0.2) 10%,
      rgba(255, 255, 255, 0.5) 70%
    );
    border: 1px solid #65a3a6;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s ease, color 0.3s ease;
  }

  .loginbtns:hover {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(101, 163, 166, 0.6) 70%
    );
    color: #ffffff;
  }

  /* .loginbtns.getStarted {
    background-color: #66a3a6;
    color: white;
    border: 1px solid #66a3a6;
  } */
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .desktop-container {
    padding: 50px;
  }
  .home-heading {
    font-size: 38px;
  }
  .home-desc {
    font-size: 22px;
  }
  .home-image {
    height: 70%;
  }
}
