.login-header {
  margin-left: -93px;
  margin-top: -48px;
  width: 675px;
  height: 84px;
  background-color: #66a3a6;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-dialog-heading {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 79%; /* 28.44px */
  text-transform: capitalize;
}

.icon-container {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the distance from the right edge */
  transform: translateY(-50%);
}

.review-container {
  width: 100%;
  height: 100%;
}

div.review-header {
  margin-top: -32px;
  width: 100%;
  height: 90px;
  background-color: #66a3a6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div.review-header > svg {
  position: absolute;
  right: 3%;
  cursor: pointer;
}

span.rating-text {
  display: block;
  font-family: Nunito;
  font-size: 26px;
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

span.rating-value {
  display: block;
  font-family: Nunito;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

/* STAR CONTAINER  */

input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
}

.star-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.star-container > label > svg {
  height: 70px;
  width: 70px;
}

textarea {
  border-radius: 20px;
  background: rgba(217, 217, 217, 0.4);
  width: 100%;
  height: auto;
  min-height: 180px;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: lowercase;
  padding: 11px;
  border-style: none;
  padding-left: 41px;
  padding-top: 18px;
  box-sizing: border-box;
}

textarea::placeholder {
  color: #000;
  margin-left: 1px;
}

.textarea-container {
  padding: 1rem 1.5rem;
}

.submit-btn-container {
  display: flex;
  justify-content: center;
  border-style: none;
}

.submit-review-btn {
  border-radius: 5px;
  background: #66a3a6;
  width: 150px;
  height: 45px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  border-style: none;
  margin-top: 16px;
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .star-container > label > svg {
    width: 50px;
    height: 50px;
  }
  .textarea-container > textarea {
    height: 9rem;
  }
  .div.review-header {
    height: 65px;
  }
}
@media only screen and (min-width: 328px) and (max-width: 768px) {
  div.review-header {
    height: max-content;
    padding: 1.5rem 0rem;
    width: 100%;
    margin-left: 0px;
  }

  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100vw;
    height: 100vh;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .reviewTextarea {
    height: 10rem;
    min-height: 150px;
  }
}
