.room-content {
  display: flex;
  gap: 0rem;
  flex-direction: column;
}

.coach-name > span {
  color: #000;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.room-name,
.room-description {
  margin-left: 11px;
  margin-top: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.room-details-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}

.customer-rating {
  position: absolute;
  top: 28%;
  left: 31%;
  display: block;
}

.customer-rating .star-rating > svg {
  width: 25px;
  height: 34px;
}

.credits {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.custRatings.coach-card {
  width: 386px;
  height: 387px;
}

.credits > .number {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.mrd.mr-root {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100vw;
}

.coach-description {
  width: 1126px;
  height: 371px;
  font-family: Nunito;
  font-size: 22px;
  margin-top: auto;
  font-style: normal;
  font-weight: 300;
  line-height: 50px; /* 227.273% */
  text-transform: capitalize;
}

.coach-img-btn {
  width: 557px;
  height: 400px;
  margin-top: 47px;
  position: relative;
}

.coach-img-btn button {
  width: 214px;
  height: 63px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #66a3a6;
  color: #fff;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  top: 316px;
  left: 7em;
}

#right-svg {
  position: absolute;
  top: 42%;
  right: 1%;
  width: 2vw;
}

#left-svg {
  position: absolute;
  top: 42%;
  left: 1%;
  width: 2vw;
}

.carousel .thumbs-wrapper {
  margin: 6px;
}

/* REVIEW SECTION  */

.room-review-content {
  width: 807px;
  height: 361px;
}

.first {
  color: #000;
  font-family: Nunito;
  font-size: 74px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.slash {
  color: rgba(0, 0, 0, 0.5);
  font-family: Nunito;
  font-size: 74px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.total {
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

p.ratings {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.5;
  margin: 0;
}

.second-col {
  display: flex;
  gap: 8%;
  justify-content: center;
  padding: 0rem 2.5rem;
}

.coach-work-info {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
}

.room-review {
  border-radius: 50px;
  background: #66a3a6;
  color: white;
  width: 275px;
  height: 65px;
  border-style: none;
}

.room-review > span {
  color: #fff;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.room-details-info {
  /* margin-left: 16em;
  margin-top: -2em; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.third-col {
  margin-left: 2vw;
  margin-bottom: 10rem;
}

.third-col > p {
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
}

.coach-card-container {
  display: flex;
  gap: 6vw;
  width: 83vw;
  justify-content: center;
}

.customer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 116px;
}

.customer-details > span {
  font-size: 20px;
}

.customer-.carousel .slide img details span.cust-name {
  color: #66a3a6;
  text-align: center;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.ratings-content-section {
  display: flex;
  align-items: center;
}

.ratings-content-section > .content {
  display: flex;
  align-items: center;
  width: 98%;
  margin-bottom: 3rem;
}

.customer-card-container {
  margin-right: auto;
  display: flex;
  gap: 50px;
}

.room-content-right {
  width: 100%;
  height: 19vw;
  position: relative;
}

.carousel .slide img {
  height: 19vw;
  object-fit: cover;
}

.carousel .thumb {
  width: 14vw !important;
  height: 7vw;
}

.carousel .thumbs {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
}

.room-content-right > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.room-preview {
  width: 99%;
  display: flex;
  height: 96px;
  gap: 3vw;
  align-items: center;
  justify-content: center;
}

.room-preview img {
  width: 237px;
  height: 96px;
  border-radius: 5px;
}

.room-name {
  font-family: Nunito;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.room-name > span {
  font-family: Nunito;
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.room-description {
  width: 91vw;
  height: auto;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 50px;
}

.book-room-btn {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 3vw;
}

.book-room-btn button {
  width: 180px;
  height: 63px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #66a3a6;
  color: #fff;
  font-family: Nunito;
  font-size: 26px;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  top: 316px;
  left: 7em;
}

#noReviewText {
  font-size: 33px;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .carousel-container {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .carousel .slide img {
    height: 179px;
  }

  .carousel .thumbs img {
    height: auto;
  }

  .ratings-content-section > .content {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  /* .ratings-content-section > .content > svg {
    transform: scale(1.5);
  } */

  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
  }

  .carousel .thumbs {
    margin-top: 6px;
    padding-left: 0vw;
  }

  .carousel .thumbs-wrapper {
    display: flex;
    gap: 0;
  }

  .carousel .thumb {
    width: 31vw !important;
    display: table-cell;
    border-radius: 14px;
    height: auto;
  }

  .carousel .thumbs-wrapper {
    margin: 0;
  }

  .room-name {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .custRatings.coach-card {
    width: 66vw;
    height: 47vw;
  }
  .room-description {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    width: 99%;
    margin: 0;
    height: auto;
    line-height: 22px;
  }

  .room-name > span {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .mv-mr.credits {
    color: #66a3a6;
    text-align: left;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 79%;
    text-transform: capitalize;
    padding-left: 1rem;
  }
  .credits {
    font-weight: 700;
  }

  .fontWeight {
    font-weight: 100;
  }

  .book-room-btn {
    margin-top: 9px;
    width: 100vw;
    margin: 0;
  }

  .book-room-btn button {
    width: 28vw;
    font-size: 4vw;
    height: 11vw;
  }

  .mrd-page-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 90vh;
    overflow-y: auto;
  }

  .first {
    font-size: 11vw;
  }

  .slash {
    font-size: 11vw;
  }
  .total {
    font-size: 6vw;
  }

  .room-details-info {
    margin-left: 0;
    margin-top: 8px;
    gap: 0.45rem;
  }

  p.ratings {
    font-size: 16px;
  }

  .room-rating-info {
    margin: 0;
    font-size: 2.5vw;
    line-height: normal;
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
  }

  .room-review {
    width: max-content;
    height: max-content;
    padding: 0.65rem 1rem;
    font-size: 16px;
    font-family: "Nunito";
    border-radius: 7px;
  }

  
  .coach-work-info + button {
    cursor: pointer;
  }

  .room-review-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 11px;
  }

  .custRatingsSection {
    width: 100%;
    position: relative;
  }

  #custRating-underline {
    margin-left: 2vw;
    position: absolute;
    border: 1px solid black;
    width: 95%;
    top: 7.5%;
    opacity: 0.1;
  }

  .custRatingsHeading {
    color: #000;
    font-family: Nunito;
    margin-left: 2vw;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border-bottom: 1px solid black;
    padding-bottom: 2vw;
    width: 47vw;
  }

  .card-svg-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .coach-img.custR {
    width: 29vw;
    height: 29vw;
    top: -26%;
  }

  .customer-rating {
    position: absolute;
    top: 20%;
    left: 34%;
  }

  .customer-rating .star-rating > svg {
    font-size: 8vw;
    width: 20px;
  }

  .nav-svg svg {
    width: 7vw;
  }

  .coach-card.custR-mv {
    width: 61vw;
    height: 68vw;
    padding: 0px 12px 0px 12px;
    margin-bottom: 18vw;
  }

  .custR-coach-card-container {
    width: 66%;
    margin: 0;
  }

  .customer-details {
    margin: 0;
    margin-top: 15vw;
  }

  .customer-details > span {
    font-size: 3vw;
  }

  .customer-details span.cust-name {
    font-size: 4vw;
  }

  .carousel-root {
    width: 100%;
  }

  .room-name-description {
    width: 91%;
    padding-left: 4vw;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .carousel.carousel-slider {
    width: 87vw !important;
    height: 24vw;
  }

  .customer-rating {
    top: 28%;
    left: 35%;
  }

  .carousel {
    width: 81.5vw;
  }

  .room-details-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 92vh;
  }

  .carousel .thumbs {
    display: flex;
    gap: 7vw;
    justify-content: flex-start;
    margin-left: -4%;
  }

  .carousel .slide img {
    height: 26vw;
    object-fit: cover;
  }

  .carousel > .thumbs-wrapper {
    margin: 0;
  }
  .thumbs-wrapper.axis-vertical ul.thumbs.animated li {
    width: 21vw !important;
    height: 10vw !important;
  }

  .room-name {
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .room-name > span {
    font-size: 3vw;
  }
  .credits > .number {
    font-size: 3vw;
  }

  .room-description {
    width: 85vw;
    height: auto;
    font-family: Nunito;
    font-size: 2.1vw;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 28px;
  }

  .book-room-btn button {
    width: 19vw;
    height: 5vw;
    border-radius: 10px;
    border: 1px solid #fff;
    background: #66a3a6;
    color: #fff;
    font-family: Nunito;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .mr-available-container > div {
    flex-direction: row;
    display: flex;
    gap: 3vw;
  }

  .second-col {
    flex-direction: column;
  }

  .room-review {
    border-radius: 50px;
    background: #66a3a6;
    color: white;
    width: 200px;
    height: 40px;
    border-style: none;
  }

  .room-review > span {
    font-size: 2.4vw;
  }

  .room-details-info {
    margin-left: 0;
    margin-top: 0;
  }

  .rating-of-5 > .first,
  .rating-of-5 > .slash {
    font-size: 7.177vw;
  }

  .total {
    font-size: 4.2vw;
  }

  .coach-work-info,
  .ratings {
    font-size: 2vw;
  }

  .awesome,
  .great,
  .good,
  .okay,
  .not-bad {
    text-align: right;
    font-family: Nunito;
    font-size: 2.2vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .book-room-btn {
    width: 86.25vw;
  }

  .second-col {
    width: 86.25vw;
    display: flex;
    gap: 3vw;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .third-col > p {
    font-size: 2.8vw;
  }

  #moduleSeparator {
    width: 81vw;
  }

  .content svg {
    width: 3.6vw;
    height: 3.6vw;
  }

  /* CUSTOMER RATINGS CARD CSS STARTS  */
  .custRatings.coach-card {
    width: 20.5vw;
    height: 21.1vw;
  }

  .cust-profilePic {
    width: 8.2vw;
    height: 8.2vw;
    border-radius: 50%;
    position: absolute;
    top: -18%;
  }

  .coach-card-container {
    display: flex;
    gap: 2vw;
    width: 78vw;
    justify-content: center;
    margin-bottom: 2vw;
  }

  .customer-rating .star-rating > svg {
    width: 1.9vw;
    height: 1.9vw;
  }

  .customer-details span.cust-name {
    color: #66a3a6;
    text-align: center;
    font-family: Nunito;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .customer-details {
    margin-top: 0;
  }

  .customer-details > span {
    font-size: 1.4vw;
  }

  /* COACH DETAILS  */
  .credits {
    color: #66a3a6;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .coach-description {
    width: 59vw;
    height: 16vw;
    line-height: 2vw;
  }

  .room-description {
    width: 91vw;
    height: auto;
    font-family: Nunito;
    font-size: 19px;
    line-height: 32px;
  }

  .customer-details > span {
    font-size: 16px;
  }
  .room-name,
  .room-name > span,
  .credits > .number {
    font-size: 28px;
  }

  .book-room-btn button {
    width: 13%;
    height: max-content;
    font-size: 20px;
    padding: 0.5rem 0rem;
  }
  .coach-img-btn {
    width: 25%;
    height: auto;
    margin-top: 30px;
    position: relative;
  }
  .coach-work-info {
    font-size: 16px;
  }

  .room-review > span {
    font-size: 20px;
  }
  .room-review {
    width: max-content;
    height: max-content;
    padding: 0.5rem 1rem;
  }

  .ratings-content-section > .content > .coach-card-container {
    width: 100%;
    gap: 1rem;
    padding-bottom: 3rem;
  }

  .ratings-content-section > .content > .coach-card-container > .customer-card {
    width: 28%;
    height: 250px;
  }

  .ratings-content-section
    > .content
    > .coach-card-container
    > .customer-card
    > .customer-rating {
    /* top: 33%; */
  }

  .ratings-content-section
    > .content
    > .coach-card-container
    > .customer-card
    > .customer-details {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .coach-img-btn button {
    width: 11vw;
    height: 3vw;
    border-radius: 10px;
    border: 1px solid #fff;
    background: #66a3a6;
    color: #fff;
    font-family: Nunito;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    position: absolute;
    top: 77%;
    left: 30%;
  }

  .custRatings.coach-card {
    width: 19vw;
    height: 19vw;
  }

  .third-col > p {
    font-size: 24px;
  }
  .first,
  .slash,
  .total {
    font-size: 48px;
  }
  .stats-section {
    width: 63%;
    box-sizing: border-box;
    /* padding: 2.5rem !important; */
    /* padding-right: 0rem !important; */
    /* padding-bottom: 1rem !important; */
    padding: unset !important;
  }
}
