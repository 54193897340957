.selectedButton{
    background-color: #66a3a6 !important;
    color: #fff !important;
    border: 1px solid #66a3a6;
  }



.booking-right-section {
  padding-left: 30px;
  padding-top: 30px;
}


/* new styles */



.bookingRequestWrapper {
  padding: 25px 135px 0 75px;
}
.bookingRequestTable {
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid #969191; */
}
.bookingRequestTable th {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  background-color: #66a3a6;
  color: #fff;
  padding: 20px 0;
  border-left: 1px solid #fff;
}
.bookingRequestTable th:first-child {
  border-left: none;
  border-radius: 10px 0 0 0;
}
.bookingRequestTable th:last-child {
  border-radius: 0 10px 0 0;
}

.bookingRequestTable td {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 45px;
  border: 1px solid #969191;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 5px;
}

table.userApprovalTable td button {
  font-family: 'Nunito';
  font-size: 12px;
}
.bookingRequestTable td span {
  cursor: pointer;
  background: #66a3a6;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  text-transform: capitalize;
}

/* scroll bar*/
/* .bookingRequestTable tbody {
  display: block;
  position: relative;
} */
.bookingReqTableContainer {
  max-height: 51.54rem;
  /* overflow-y: scroll; */
  position: relative;
}

.mobileViewBookingsDetails{
  display: none;
}
/* .bookingReqTableContainer tbody {
  scrollbar-color: #2980b9 #db343c;
  scrollbar-width: thin;
}
.bookingReqTableContainer tbody::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  background-color: #db343c;
}

.bookingReqTableContainer tbody::-webkit-scrollbar-thumb {
  background-color: #2980b9;
  border-radius: 4px;
} */
.mobPag{
  display: none;
}
/* scroll bar updated code*/
.bookingReqTableContainer::-webkit-scrollbar {
  border-radius: 0px 0px 9px 0px;
  background: rgba(0, 0, 0, 0.1);
  width: 8px;
}

.bookingReqTableContainer::-webkit-scrollbar-thumb {
  background: #66a3a6;
}

.bookingReqTableContainer::-webkit-scrollbar-button:start {
  display: none;
}

.bookingReqTableContainer::-webkit-scrollbar-button:end {
  display: none;
}
.bookingReqTableContainer {
  scrollbar-color: #66a3a6 rgba(0, 0, 0, 0.1);
  /* scrollbar-width: thin; */
}
.bookingReqTableContainer::-webkit-scrollbar-track-piece {
  width: 15px;
  height: 14px;
}
.sortButtons button {
  cursor: pointer;
  background: #fff;
  color: #66a3a6;
  padding: 3px 5px;
  border-radius: 5px;
  text-transform: capitalize;
  border: 1px solid #66a3a6;
  font-size: 20px;
}
.sortButtons button:last-child {
  margin-left: 10px;
}
.sortButtons {
  margin: 0px 0px 10px;
  display: flex;
}
.selectedButton{
  background-color: #66a3a6 !important;
  color: #fff !important;
  border: 1px solid #66a3a6;
}
.acceptedStatus {
  background-color: green;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}
.rejectedStatus {
  background-color: red;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}
.pendingStatus {
  background-color: orange;
  color: black;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
  font-family: 'Nunito';
}
.marginRemove .pagination{
  margin-inline-end: 0rem;
}

.react-loading-overlay__spinner {
  z-index: 9999;
}
.react-loading-overlay__overlay {
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: darken the background */
}


/* .hideBtnClass {
  cursor: not-allowed !important;
  background-color: grey !important;
  opacity: 0.6;
} */



@supports (-moz-appearance: none) {
  .bookingRequestTable th:first-child {
    border-left: 1px solid #fff;
  }
  .bookingRequestTable tr {
    border-left: 1px solid #969191;
  }
  .bookingReqTableContainer::-webkit-scrollbar-track-piece {
    width: 15px;
  }
}

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .bookingRequestWrapper {
    padding: 25px 60px 0 50px;
  }
}

@media only screen and (max-width: 1025px) {
  .bookingRequestWrapper {
    padding: 25px 30px 0 40px;
  }
  .bookingRequestTable td {
    font-size: 10px;
  }
}

@media only screen and (max-width: 450px){

  
  .bookingRequestWrapper{
    padding-top:22%;
    padding-left: 17px;
    padding-right:17px ;
  }

  .bookingReqTableContainer{
    display: none;
  }

  .sortButtons button{
    height: 30px;
    width: 35%;
    border:none;
    background-color:white;
    color:black;
    border: solid 1px black;
  }

  .sortButtons button:focus{
    background-color:#66a3a6;
    color:white;
    border: none;
  }

  .mobileViewBookingsDetails{
    display: block;
    min-height: 100vh;
  }

  .bookingDeatilContainer{
    width: 90vw;
    height: 17vh;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 20px;
  }

  .imageAndDetailsContainer{
    display: flex;
    align-items: center;
  }

  .imageAndDetailsContainer .detailsContainer p{
    font-size: 15px;
    margin: 8px 0;
  }

  .detailsContainer{
    padding-left: 10px;
  }

  .detailsContainer p:nth-child(1){
    font-size: 15px;
    font-weight: 600;
  }

  .detailsContainer p:nth-child(2){
    font-size: 13px;
    font-weight: 300;
  }

  .detailsContainer p:nth-child(3){
    color: #66a3a6;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .imageContainer{
    width: 30%;
    height: 100%;
  }

  .imageContainer img{
    width:100%;
    height: 100%;
    border-radius: 10px;
  }

  .viewDetailsPageBtnContainer{
    display: flex;
    align-items: center;
  }

  .viewDetailsPageBtnContainer .placeHolderbtn{
    width: 24px;
    height: 24px;
    border: solid 1px black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .viewDetailsPageBtnContainer .placeHolderbtn svg{
    width: 18px;
    height: 18px;
  }

  .detailsContainer button{
    height: 20px;
    font-size: 12px;
  }

  .sortButtons {
    margin: 20px 0px 10px;
  }
  .mobPag{
    display: block;
  }
  
}
