.leftRightSectionContainer {
  display: flex;
  flex-direction: row;
}

.inputFieldLabel > span,
.phoneInputlabel > span,
.namesInputLabel > span,
.defaultLabelStyle > span {
  font-size: 16px !important;
  color: #000000;
  opacity: 1 !important;
}
.rightSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  padding-top: 2rem;
  gap: 1rem;
}

.rightSection > svg {
  position: fixed;
  top: 1%;
  right: 1%;
}

.register-input-field {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.register-input-field > div {
  width: 100%;
}

.register-input-fields {
  display: flex;
  justify-content: space-between;
}

/* .logo-data.register-logo {
  padding-top: 40%;
} */
/* .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  height: auto !important;
} */

/* .MuiInputLabel-formControl {
  display: none !important;
} */
.registerHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5%;
}

.registerForm {
  display: flex;
  flex-direction: column;
  width: 86%;
}

.namesInputLabel > span {
  font-size: large;
  font-weight: normal;
  width: 100%;
}

.namesInputField {
  margin: 10px 0px;
  display: flex;
  gap: 5%;
}

.nameInputFieldStyle {
  width: 55%;
}

.phoneInput {
  width: 45%;
  margin-top: 10px;
}
.emailMobileInputs > .registerinput {
  width: 45%;
}

.phoneInput > div {
  margin: 10px 10px 10px 0px;
  width: 100%;
}

.radioContainer {
  margin-top: 10px !important;
}

.radioLabelStyle > span {
  opacity: 1;
}

.radioLabelStyle > span > span {
  opacity: 1;
}

.defaultLabelStyle {
  font-size: large;
  font-weight: normal !important;
  color: black !important;
}

.registerBtn {
  margin: 20px 0 10px 10px;
  width: 85%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4%;
}

.loader {
  height: 100%;
  height: 70vh;
}

.nameInputs {
  width: 100%;
}

.radioLabelStyle > span {
  color: black;
}

.emailMobileInputs {
  /* margin-right: 0px;
  display: flex;
  gap: 5%; */
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1330px) {
  .registerForm {
    margin-left: 20px;
  }

  .inputField {
    width: 90%;
  }

  .nameInputs {
    width: 94%;
  }

  .inputFieldLabel,
  .namesInputLabel > span,
  .defaultLabelStyle {
    font-size: medium;
    font-weight: normal;
    color: black;
  }

  .namesInputLabel > .MuiFormControl-root > label {
    display: none !important;
  }

  .namesInputField {
    margin-right: 10px;
  }

  .phoneInput > div {
    width: 100%;
  }
}
.leftSection > div > svg {
  width: 60%;
}

/* LEFT SECTION */

.leftSection {
  width: 50%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftSection > img {
  position: fixed;
  top: 75%;
  width: 30%;
}

.leftSection > div {
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leftSection.QbleftSection {
  background-color: #66a3a6;
}

.QbLeftSectionInnerContainer > svg {
  width: 30% !important;
}

.leftSection > div > img {
  width: 65%;
}

@media only screen and (max-width: 768px) {
  .leftRightSectionContainer {
    flex-direction: column;
  }

  .leftSection {
    width: 101%;
    align-items: center;
  }

  .leftSection > div > img {
    width: 50%;
  }

  .leftSection > img {
    position: relative;
    width: 50%;
  }

  .rightSection {
    width: 101%;
    height: 50%;
  }

  .registerForm {
    max-height: 66%;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .emailregister {
    width: 100%;
  }

  .nameInputs {
    width: 100%;
  }

  .logo-data.register-logo {
    padding-top: 0;
  }

  .registerHeading > img {
    width: 50%;
    height: 10px;
  }

  .rightSection > svg {
    position: fixed;
    top: 1%;
    right: 3%;
  }

  .namesInputField {
    margin-right: 10px;
  }

  .registerBtn {
    justify-content: center;
  }
  .namesInputLabel > span {
    width: unset !important;
  }
}

@media only screen and (max-width: 768px) {
  .registerForm {
    width: 85%;
    align-items: center;
    height: max-content;
    max-height: max-content;
    padding-right: 1rem;
  }

  .leftSection.QbleftSection {
    background-color: unset;
  }
  .inputFieldLabel {
    width: 100%;
  }

  .defaultLabelStyle {
    width: 100%;
  }

  .css-j204z7-MuiFormControlLabel-root {
    width: 100%;
  }

  .leftSection > div > img {
    width: 70%;
  }

  .leftSection > img {
    width: 50%;
    height: 40%;
  }

  .inputContainer > div > label {
    overflow: visible;
  }

  .namesInputField > div > label {
    overflow: visible;
  }

  .namesInputField {
    margin-right: 10px;
  }
  /* .register-input-fields,
  .emailMobileInputs {
    flex-direction: column;
    align-items: center;
    width: 90% !important;
  }
  .inputContainer {
    width: 90% !important;
  }
  .register-input-field,
  .registerinput,
  .phoneInput {
    width: 100% !important;
  }
  .emailregister {
    width: 90% !important;
  }
  .inputContainer > div {
    width: 100% !important;
  } */
  .register-input-fields,
  .emailMobileInputs {
    flex-direction: column;
    width: 100% !important;
    gap: 1rem;
  }
  .register-input-field {
    width: 100%;
  }
  .registerinput,
  .phoneInput {
    width: 100% !important;
  }
  .rightSection {
    height: 100vh !important;
  }
  .MuiFormGroup-root > label {
    width: 100% !important;
  }
  .rightSection {
    padding: unset;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .registerBtn > button {
    width: max-content !important;
  }
}
