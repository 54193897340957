.mr-form.bmr {
  width: auto;
  height: auto;
  margin: 0;
  border-radius: 0px;
  border-top-left-radius: 3vw;
  border-top-right-radius: 3vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.bmr-form-header {
  width: 100%;
  height: 82px;
  border-radius: 10px 10px 0px 0px;
  background: #66a3a6;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 79%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rf1.input-container {
  width: auto;
  height: 430px;
  display: flex;
  gap: 3em;
 margin-bottom: 0;
}

.rf-right-section {
  width: 87%;
  height: 86vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bmr-input-container {
  position: relative;
  display: inline-block;
  width: 503px;
    height: 41px;
}

.field label {
  color: #000;
  text-align: left;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
}

.field input {
  width: 587px;
  height: 66px;
}

.bmr-input-container input {
  padding-left: 0.5vw;
  /* width: 100%; */
  width: 94%;
  height: 41px;
}

.bmr-column1,
.bmr-column2 {

  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.bmr-column1 label,
.bmr-column2 label {
  color: #000;
  font-family: Nunito;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
  opacity: 0.5;
  margin-top: 6px;
}

/* ICON CONTAINER SVG STYLING    */

.bmr1-icon-container {
  position: absolute;
  top: 23%;
  right: 6%;
}

.bmr6-icon-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.bmr8-icon-container {
  position: absolute;
  top: 48%;
  right: 6%;
  transform: translateY(-50%);
  z-index: 0;
}

.bmr3-icon-container,
.bmr5-icon-container {
  position: absolute;
  top: 29%;
  right: 6%;
}

/* SUBMIT BUTTON  */

  .bmr-submit {
    width: 200px;
    height: 52px;
    border-radius: 10px;
    background: #66a3a6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 79%;
    text-transform: capitalize;
    border-style: none;
    margin-bottom: 2.5vw;
}

.bmr-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin-top: 2.5em;
  padding-left: 5rem;
  padding-right: 5rem;
}

.bmr-form .dropdown-container {
  position: relative;
  width: 510px;
  height: 41px;
  /* margin-bottom: 1rem; */
}

.bmr-form .dropdown-icon-container {
  position: absolute;
  right: 24px;
  top: 12px;
}

.bmr-form .dropdown-options {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 508px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #ccc;
  max-height: 128px;
  overflow-y: auto;
}

.bmr-form .selected-room-type {
  padding-left: 0.5vw;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 94%;
  border: 1px solid rgba(0,0,0,0.5);
}

.error-hidden {
  /* visibility: hidden; */
  width: 167.06px;
  height: 20px;
}

.bmr-tel-icon-container {
  position: absolute;
  top: 15%;
  right: 4%;
}

.seats-label {
  color: #000;
  font-family: Nunito;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
  opacity: 0.5;
  margin-top: 14px;
}

.seats-ip {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 11px;
}

input[type="datetime-local"] {
  font-size: 19px;
  font-weight: 300;
  font-family: Nunito;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  font-size: 25px;
  position: absolute;
  right: 17px;
  font-size: 29px;
  top: 1px;
  opacity: 0;
  z-index: 1;
}

.label-ip-container.bmr {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .mv-form-root {
    width: 100vw;
    height: 82vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mv-mr-form-header {
    width: 100%;
    background: #66a3a6;
    height: 11vw;
    font-size: 4.9vw;
    font-family: "Nunito";
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;
  }



  .mv-br-input-container {
    display: flex;
    width: 81vw;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .bmr-column1 {
    font-size: 2.9vw;
    width: 50%;
  }

  .bmr-input-container {
    width: 158px;
    height: 32px;
  }

  .bmr-input-container input {
    width: 33vw;
    height: 6.5vw;
    border-radius: 0px;
    font-size: 2.9vw;
    padding-left: 2px;
    padding-top: 2px;
  }

  .bmr-form {
    padding: 3vw 7vw 3vw 12vw;
  }

  .bmr-form .dropdown-container {
    width: 33vw;
    height: 5.6vw;
    border-radius: 0px;
    border: 1px solid black;
  }

  .bmr1-icon-container {
    position: absolute;
    top: 20%;
    left: 87%;
  }





  .bmr-form .dropdown-options {
    width: 100%;
    font-size: 2.9vw;
  }

  .bmr-column1 label,
  .bmr-column2 label {
    width: auto;
    font-size: 3vw;
  }

  .bmr-form .dropdown-icon-container {
      position: absolute;
      right: 5%;
      top: 7%;
  }

  .selectedDropdowntype {
    width: 100%;
    height: 100%;
    font-size: 2.9vw;
    padding-left: 2px;
    padding-top: 2px;
  }

  .mv-bmr-btn-container {
    display: flex;
    justify-content: center;
  }

  .bmr-submit {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    width: 33vw;
    height: 13vw;
    margin: 0;
  }
}
