.home-stats-container {
  display: flex;
  flex-direction: row;
}

.home-stats-root {
  display: flex;
  flex-direction: column;
}

.home-right-stats-section {
  margin-left: 1.5rem;
}

.room-info {
  margin-top: -16px;
}

/* DELETE BEFORE PUSHING  */
/* .statistics > p,
.room-info > p,
.coach-info > p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-weight: 700;
  text-transform: capitalize;
} */

.home-inner-stats {
  width: 1177px;
  height: 152px;
  border-radius: 10px;
  border: 1px solid #66a3a6;
  margin-top: 27px;
}

.stats-bookings {
  width: 350px;
  height: 152px;
  border-right: 1px solid #66a3a6;
  margin-top: -1em;
}

.num-stats {
  display: flex;
}

.stat .number {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  line-height: normal;
  text-transform: capitalize;
}

.stat p {
  margin: 0;
  color: #66a3a6;
  text-align: center;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

/* ROOMS AND UPDATES  */

.rooms-and-updates {
  display: flex;
  margin-top: 38px;
  gap: 49px;
}

.room-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 31px;
  height: auto;
}

.room {
  width: 21%;
  height: 287px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
  position: relative;
  background-color: #ffffff;
  box-sizing: border-box;
}

.room-img {
  height: 155px;
  border-radius: 10px;
  width: 100%;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
}

.room-details > span {
  color: #000;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.room-details > p {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.room > p {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 19px;
  margin-bottom: 14px;
}

.room-details > svg {
  width: 66px;
}

.details-and-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* coach cards */

.coach-card-container {
  display: flex;
  gap: 50px;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .room-cards {
    display: flex;
    gap: 2rem;
  }
  .room {
    width: 29%;
    height: 300px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .room-cards {
    flex-wrap: nowrap;
  }
}
