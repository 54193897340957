.coach-reschedule-booking-form-container {
  width: 100% !important;
  margin-top: unset !important;
  box-shadow: unset !important;
  margin-left: unset !important;
}

.coach-reschedule-booking
  > .MuiDialog-container
  > .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 40% !important;
  height: max-content !important;
  max-width: unset;
}

.coach-reschedule-booking-form-container > header > div {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 600;
  line-height: 22.12px;
  text-align: center;
}
.reschedule-form-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
}
.reschedule-form-buttons > button {
  cursor: pointer !important;
}
.reschedule-form > div > div {
  width: 90% !important;
}
.cancel-booking-header {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 600;
  line-height: 36.12px;
  text-align: center;
}
.cancel-booking-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.reschedule-cancel-dialog-cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.reschedule-cancel-dialog-cancel-button > button {
  width: 100px;
  cursor: pointer;
}

.reschedule-cancel-button {
  background-color: #ffffff !important;
  color: #66a3a6 !important;
  border: 1px solid #66a3a6 !important;
}
