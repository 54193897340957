.mr-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: linear-gradient(180deg, #e3f5f5 0%, #ffffff 100%);
}

.mr-right-section.coaches {
  padding: 0;
}

.coaches-root {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

.coaches.coach-card-container {
  display: flex;
  gap: 4vw;
  width: 89vw;
  justify-content: flex-start;
  flex-wrap: wrap;
}

/* FILTERS  */

.mr-filter-container {
  width: 1546px;
  height: 119px;
  margin-top: 23px;
  display: flex;
}

.mr-filter-top,
.mr-filter-bottom {
  display: flex;
}

.mr-filter-bottom {
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.mr-filter1 {
  width: 362px;
  height: 63px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  padding: 0px 14px;
}

/* FILTER BOTTOM  */

.mr-filter-icon {
  width: 60px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid;
}

.mr-filter-capacity {
  width: 137px;
  border-right: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 33px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.mr-filter-skills.mr-dropdown {
  width: 305px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
}

.mr-filter-skills.mr-dropdown input {
  height: 89%;
  width: 100%;
  border: none;
  font-size: 20px;
  font-family: "Nunito";
  font-weight: 100;
}

.coaches-filter-btn {
  width: 460px;
  height: 119px;
  background-color: #66a3a6;
  color: #fff;
  font-family: Nunito;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coach-right-section {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  width: 92%;
  max-height: 80vh;
  overflow-y: scroll;
  padding-left: 2vw;
  overflow-x: hidden;
}

.mr-sidebar {
  border: 1px solid;
  width: 118px;
  border-bottom: none;
  border-top: none;
}

.coaches.coach-card {
  width: 15.73%;
  height: auto;
}

.room-info {
  margin-top: -16px;
}

.coach-img.coaches {
  width: 37%;
  height: 45%;
}

.statistics > p,
.room-info > p,
.mr-available > p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

/* ROOMS AND UPDATES  */

.coach-allcoaches {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-top: 2%;
}

.room-cards {
  display: flex;
  gap: 42px;
}

.room {
  width: 350px;
  height: 283px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
}

.room-img {
  width: 352px;
  height: 174px;
  border-radius: 10px;
}

.room-details > span {
  color: #000;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.room-details > p {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.room > p {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 19px;
  margin-bottom: 14px;
}

.room-details > svg {
  width: 66px;
}

.paginationNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8%;
  gap: 1%;
  margin-top: 1.5vw;
}

.details-and-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* coach cards */

.room-img {
  width: 352px;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
}

/* AVAILABLE MEETING ROOMS  */

.coach-available {
  margin-bottom: 2vw;
}

.coach-rating.coaches {
  position: absolute;
  top: 20%;
}

.coaches.coach-card p {
  margin: 0;
}

.coach-box-info > p,
.coach-available > p {
  color: #000;
  font-family: Nunito;
  margin: 0;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

/* NEWS AND UPDATES */

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .mr-filter1 {
    width: 30%;
    height: 100%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
  }

  .mr-filter-icon {
    width: 60px;
    height: 3.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid;
  }
  .coaches.coach-card-container {
    width: 100%;
    gap: 2rem;
  }
  .coach {
    width: 30%;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    padding: 11px 10px 19px 11px;
    position: relative;
    background-color: #ffffff;
    box-sizing: border-box;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .coaches.coach-card-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 58px;
    width: 84vw;
    max-height: 80vh;
  }

  .mr-right-section.coaches-page {
    overflow: hidden;
    max-height: 92%;
  }

  .meeetingrooms-root.coachesRoot.tablet {
    overflow: hidden;
  }

  .coach-allcoaches {
    overflow: auto;
  }

  .mr-filter-skills.mr-dropdown {
    width: auto;
  }

  .coaches.coach-card-container > div {
    flex-direction: row;
    display: flex;
    gap: 3vw;
  }

  .mr-filter-skills.mr-dropdown input {
    font-size: 2vw;
  }

  .coach-cards {
    display: flex;
    gap: 5vw;
    flex-wrap: nowrap;
    max-width: 80vw;
    overflow-x: hidden;
    height: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }

  .coach-box-info > p,
  .coach-available > p {
    color: #000;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 8px 0px 8px 0px;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .coach-card-container.mv-coaches {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding-left: 0;
    gap: 9px;
    overflow-y: scroll;
    width: 100vw;
    justify-content: flex-start;
    padding-bottom: 20px;
    height: 76vh;
    overflow: hidden auto;
  }

  .paginationNumbers {
    display: none;
  }

  .coach-rating.coaches {
    position: absolute;
    top: 20%;
  }

  .coach-rating {
    top: 22%;
  }

  .coach-details.mv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .coaches.nav-filterSection {
    /* position: fixed; */
  }

  .coach-card.mv-coaches {
    height: 38vw;
    width: 66%;
  }

  .coach-card.mv-coaches .coach-img {
    width: 23vw;
    height: 23vw;
    top: -72px;
  }

  .coach-card.mv-coaches p {
    font-size: 3.5vw;
  }

  .coach-card.mv-coaches span {
    font-size: 2.9vw;
  }

  .room-rating > svg {
    width: 7vw;
  }

  .coach-rating > svg {
    width: 5vw;
  }

  .coach-view-btn {
    width: 24vw;
    height: 7vw;
    font-size: 4vw;
    margin-top: 10px;
  }

  .mr-filter-skills.mr-dropdown input {
    font-size: 2.5vw;
  }

  .mr-filter-skills.mr-dropdown {
    width: 31vw;
  }
}
