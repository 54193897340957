.news-and-updates {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.news-and-updates > hr {
  opacity: 0.2;
}

.news-and-updates p {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  gap: 6%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.news-and-updates > .heading > p {
  font-weight: 700;
  color: #66a3a6;
}
/* NEWS CONTAINER  */

.news-container {
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: 99%;
}

.news-image {
  width: 79px;
  height: 96px;
  object-fit: contain;
}

.news {
  display: flex;
  gap: 17px;
  padding-top: 15px;
  padding-left: 15px;
}

.news-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.news-details > p {
  text-align: start;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0em;
  margin-top: 0em;
}

.news-details > span {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  text-overflow: ellipsis;
}

/* SCROLL BAR  */

/* Track */

.news-container::-webkit-scrollbar {
  border-radius: 0px 0px 9px 0px;
  background: rgba(0, 0, 0, 0.1);
}

.news-container::-webkit-scrollbar-thumb {
  background: #66a3a6;
}

.news-container::-webkit-scrollbar-button:start {
  display: none;
}

.news-container::-webkit-scrollbar-button:end {
  display: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .news-and-updates {
    width: 22vw;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 85vh;
    height: 84%;
  }

  .news {
    display: flex;
    box-sizing: border-box;
    gap: 6px;
    width: 100%;
    align-items: center;
    padding: 4px 8px;
  }

  .news-image {
    /* padding: 10px; */
  }
  .news-and-updates p {
    font-weight: bold;
    margin: 9px;
    color: #000;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .news-details > p {
    text-align: start;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
    font-weight: 700;
  }

  .news-details > span {
    color: #000;
    font-family: Nunito;
    font-size: 11.5px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: capitalize;
  }

  .news-details {
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
  }

  .news-and-updates {
    /* margin-top: 2.4vw; */
  }

  .news-container {
    overflow-y: auto;
  }
}

@media only screen and (max-width: 900px) {
  .news-and-updates {
    width: 98%;
    max-height: 100%;
    overflow-y: scroll;
    height: 40vh;
  }

  .header-heading {
    color: white !important;
    font-size: 19px;
  }

  .news-details p {
    font-size: 15px;
    color: black;
    font-weight: 700;
    margin: 0;
  }

  .news-container {
    width: 100%;
  }

  .news-details {
    gap: 0;
    width: 100%;
  }

  .news-details > span {
    width: 100%;
    font-size: 13px;
  }

  .news {
    display: flex;
    gap: 17px;
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 1vw;
  }

  .news-image {
    width: 71px;
    height: 67px;
  }
}
