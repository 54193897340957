/* General styling for table and container */
.bookingReqTableContainer {
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  overflow-x: auto; /* For horizontal scroll on small screens */
}

.mbReqTableContainer{
  height: 69vh;
}


.bookingRequestTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.notFoundText {
  height: 100%;
  width: 97%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito";
}

.rmh {
  height: 81%;
}

.bookingRequestTable th,
.bookingRequestTable td {
  padding: 5px 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.bookingRequestTable th {
  font-size: 19px;
}

.bookingRequestTable td button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.bookingRequestTable td button:hover {
  opacity: 0.8;
}

.bookingRequestTable td button[disabled] {
  cursor: not-allowed;
}

.bookingRequestTable td span {
  color: #007bff;
  cursor: pointer;
}

.bookingRequestTable td span:hover {
  text-decoration: underline;
}

/* Status button color variations */
.statusbtn {
  background-color: #ffc107;
}

button.accepted {
  background-color: #28a745; /* Accepted (Green) */
}

button.rejected {
  background-color: #dc3545; /* Rejected (Red) */
}

/* Responsive Design */
@media (max-width: 768px) {
  .bookingRequestTable thead {
    display: none;
  }

  .bookingRequestTable,
  .bookingRequestTable tbody,
  .bookingRequestTable tr,
  .bookingRequestTable td {
    display: block;
    width: 100%;
  }

  .bookingRequestTable tr {
    margin-bottom: 15px;
  }

  .bookingRequestTable td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .bookingRequestTable td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    width: calc(50% - 30px);
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .bookingRequestTable td button {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .bookingReqTableContainer {
    padding: 5px;
  }
}
