.formFlexClass {
  display: flex;
  gap: 40px;
  margin-bottom: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioLabelStyle {
  display: flex;
}

.radioContainer input[type="radio"] {
  display: flex;
  height: fit-content;
  width: 16px;
}

.formInputWrapper.fullMailAddress {
  width: 94%;
}

.fullMailAddress > textarea {
  width: 100%;
  height: 80px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  min-height: 30px;
  padding-left: 7px;
  padding-top: 2px;
}

.formWrapper {
  width: 93%;
}

.formInputWrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.formInputWrapper > div > .PhoneInput > .PhoneInputCountry + input[type="tel"] {
  width: 20vw;
}
.formInputWrapper input .formSection {
  height: 708px;
  overflow: hidden;
  width: 50%;
}

.formSection {
  width: 60%;
  padding: 0rem 2rem;
  height: 70%;
  max-height: 80vh;
}
.locationInputWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 30px 0 50px;
}

.formInputWrapper label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}

.formInputWrapper input {
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #b7b2b2;
  padding-left: 4px;
}

.formInputWrapper input[type="tel"] {
  border: none !important;
}
.PhoneInput {
  padding-left: 4px;
  border: 1px solid #b7b2b2;
  border-radius: 5px;
  width: 100%;
}

.locationInputWrapper label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}

.locationInputWrapper input {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b7b2b2;
}

.buttonWrapper button:first-child {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background-color: #66a3a6;
  border: none;
  width: 125px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.buttonWrapper button:nth-child(2) {
  font-family: Inter;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  background-color: #fff;
  border: 1px solid #000;
  width: 125px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.formProfileImg {
  text-align: center;
  margin: 1vw 0;
  position: relative;
  overflow: hidden;
}

.profileImage {
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.formProfileImgWrapper {
  position: relative;
}

.overlayIcon {
  position: absolute;
  top: 78%;
  left: 53%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 30px;
}

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (min-width: 328px) and (max-width: 768px) {
  .formSection {
    width: 75%;
    padding-bottom: 4vw;
  }

  .formInputWrapper > label {
    display: none;
  }

  .profileImage {
    width: 29vw;
    border-radius: 50%;
    height: 28vw;
    position: relative;
  }

  .overlayIcon {
    position: absolute;
    top: 83%;
    left: 79%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 25%;
    height: 25%;
  }

  .formInputWrapper {
    width: 100%;
  }

  .formInputWrapper label {
    font-family: Inter;
    font-size: 2.8vw;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5px;
    width: 36%;
  }

  .formFlexClass {
    flex-direction: column;
    gap: 14px;
  }

  #updateProf {
    width: 100%;
  }

  .formInputWrapper input {
    height: 7vw;
    border-radius: 5px;
    border: 1px solid #b7b2b2;
    font-size: 16px;
    padding: 6px;
  }

  .buttonWrapper button:first-child {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #66a3a6;
    border: none;
    width: 30%;
    height: 2.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .buttonWrapper button:nth-child(2) {
    font-family: Inter;
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    background-color: #fff;
    border: 1px solid #000;
    width: 30%;
    height: 2.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .profileTag > svg {
    transform: scale(1.05);
  }
  .profileTag {
    width: max-content;
    height: max-content;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .formSection {
    background-color: #fff;
    display: flex;
    width: 55%;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    position: relative;
  }

  .profileImage {
    width: 120px;
    border-radius: 50%;
    height: 120px;
  }

  .overlayIcon {
    height: unset;
    left: unset;
    right: 38%;
  }

  .formWrapper {
    width: 100%;
  }

  #updateProf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .formFlexClass {
    margin: unset;
  }

  .formInputWrapper input {
    width: 100%;
    /* height: 60px; */
    border-radius: 5px;
    border: 1px solid #b7b2b2;
    font-size: 18px;
    padding-left: 4px;
  }
  .formInputWrapper input[type="tel"] {
    border: none !important;
  }
  .PhoneInput {
    border: 1px solid #b7b2b2;
    border-radius: 5px;
  }

  .formInputWrapper label {
    font-size: 20px;
  }

  .buttonWrapper button:first-child {
    font-family: Inter;
    font-size: 1.5vw;
    font-weight: 500;
    color: #fff;
    background-color: #66a3a6;
    border: none;
    width: 9vw;
    height: 3vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .buttonWrapper button:nth-child(2) {
    font-family: Inter;
    font-size: 1.5vw;
    font-weight: 300;
    color: #000000;
    background-color: #fff;
    border: 1px solid #000;
    width: 9vw;
    height: 3vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .PhoneInput {
    width: 100%;
    height: 60px;
  }

  .wallet-container > img {
    width: 24vw;
  }

  .profileTag.active {
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .formInputWrapper > label {
    font-size: 13px;
  }

  .formInputWrapper input {
    height: 32px;
    font-size: 13px;
  }

  .PhoneInput {
    width: 100%;
    height: 32px;
  }

  .formInputWrapper
    > div
    > .PhoneInput
    > .PhoneInputCountry
    + input[type="tel"] {
    width: 100%;
    height: 90%;
  }

  .profileTag h2 {
    font-size: 13px;
  }

  .profileTag svg {
    width: 17px;
  }

  .buttonWrapper button:first-child,
  .buttonWrapper button:nth-child(2) {
    font-size: 13px;
    height: auto;
    width: 100%;
    padding: 6px 16px;
  }

  .overlayIcon {
    right: 41%;
    width: 3.5%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .formSection {
    width: 74vw;
    height: 76vw;
  }

  .formInputWrapper label {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5px;
  }
  .profileTag.active {
    height: 4vw;
  }

  .profileTag {
    height: 4vw;
  }

  .profileTag svg {
    width: 2.5vw;
    height: 6vw;
  }

  .profileTag h2 {
    font-family: Nunito;
    font-size: 15px;
    font-weight: 300;
  }
  .formInputWrapper input {
    width: 31vw;
    height: 5vw;
    font-family: Nunito;
  }

  .formInputWrapper
    > div
    > .PhoneInput
    > .PhoneInputCountry
    + input[type="tel"] {
    width: 35.2vw;
  }

  .buttonWrapper button:first-child {
    font-family: Inter;
    font-size: 1.9vw;
    font-weight: 500;
    color: #fff;
    background-color: #66a3a6;
    border: none;
    width: 13vw;
    height: 31px;
    border-radius: 5px;
    cursor: pointer;
  }

  .buttonWrapper button:nth-child(2) {
    font-family: Inter;
    font-size: 1.9vw;
    font-weight: 300;
    color: #000000;
    background-color: #fff;
    border: 1px solid #000;
    width: 13vw;
    height: 31px;
    border-radius: 5px;
    cursor: pointer;
  }

  .overlayIcon {
    left: 61%;
  }
}
