.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  height: 100vh;
}

.payment-container > div {
  font-family: "Nunito";
}

#checkOutForm {
    background-color: rgb(246, 249, 252);
    padding: 31px 60px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

#checkOutBtn {
  width: 100%;
  height: 41px;
  border-radius: 10px;
  background: #66a3a6;
  font-size: 15px;
  font-family: 'Nunito';
  color: white;
  border: none;
}


@media only screen and (max-width: 768px) and (min-width: 328px) {
  .payment-container {
    flex-direction: column;
  }
}