.qb-container {
  display: flex;
}

.qb1-left-section {
  width: 30%;
  border-radius: 20px 0px 0px 20px;
  background: #66a3a6;
  height: 729px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qb-logo {
  width: 153px;
  height: 145px;
  margin-top: 21px;
}

.status-details {
  display: flex;
  gap: 7px;
  margin-top: 2em;
}

.details {
  display: flex;
  margin-top: -4px;
  flex-direction: column;
  gap: 64px;
  color: white;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.qb-heading1 {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.qb-heading2 {
  color: #66a3a6;
  font-family: Nunito;
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.qb1-left-section > span {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-weight: 300;
  font-family: Nunito;
  width: 386px;
  height: 55px;
  border-radius: 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.qb1.qb-right-section {
  width: 884px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qb.label-ip-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.qb-column1,
.qb-column2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.qb-form {
  display: flex;
  gap: 35px;
  margin-left: 34px;
  margin-top: 46px;
}

.qb-form input {
  width: 386px;
  height: 55px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 20px;
  padding-left: 11px;
  font-family: Inter;
}

.qb-container > svg {
  position: absolute;
  right: 14px;
  top: 9px;
  cursor: pointer;
}

.qb1-img {
  width: 14vw;
}

.error {
  color: red;
}

.qb.submit-btn {
  width: 8vw;
  height: 2.3vw;
  border-radius: 10px;
  background: #66a3a6;
  color: white;
  border: none;
  color: #fff;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.qb1-icon-container {
  position: absolute;
  right: 3%;
  transform: translateY(-50%);
  height: 4vw;
}

label.qb-label {
  color: rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .qb1.qb-right-section {
    width: 71%;
  }

  .qb-laptop-container {
    display: flex;
    height: 100%;
  }

  .qb-laptop-container > svg {
    position: absolute;
    right: 14px;
    top: 9px;
    cursor: pointer;
  }

  .qb1-left-section {
    width: 31%;
    height: 100%;
  }

  .qb1-left-section > span {
    width: 100%;
    height: auto;
    font-size: 1vw;
    font-weight: lighter;
  }

  .qb-logo {
    width: 11vw;
    height: 10vw;
  }

  .qb-heading1 {
    font-size: 2vw;
  }

  .qb1.details {
    font-size: 1vw;
    font-weight: 100;
    margin-top: 0;
    gap: 5.2vw;
  }

  .status-details > img {
    height: 14vw;
  }

  .qb1-img {
    width: 20vw;
    margin-bottom: -3vw;
  }

  .qb-heading2 {
    font-size: 2.5vw;
  }
  .qb-form {
    margin-left: 0;
  }

  label.qb-label {
    font-size: 1.27vw;
  }

  .qb-form input {
    width: 26vw;
    height: 3vw;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 1.25vw;
    padding-left: 11px;
    font-family: Inter;
  }

  .qb1-icon-container {
    position: absolute;
    right: 3%;
    transform: translateY(-50%);
    height: auto;
    top: 54%;
  }

  .qb.submit-btn {
    width: 10vw;
    height: 3.3vw;
    border-radius: 10px;
    background: #66a3a6;
    color: white;
    border: none;
    color: #fff;
    font-family: Nunito;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  /* TOP SECTION  */
  .qb-top-section {
    height: 422px;
    width: 100%;
    background-image: url("../assets/png/Tab-Qb1Img.png");
    object-fit: cover;
    background-color: #66a3a6;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px 20px 0px 0px;
    /* background-size: 100% 100%; */
    background-position: center 112%;
  }

  .qb-top-section > .qb-heading1 {
    color: #fff;
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 5px 0px 5px 0px;
  }

  .svgicon {
    padding-top: 5%;
  }

  .qb-desc {
    font-family: "Nunito";
    font-size: 18px;
    color: white;
    width: 37%;
    text-align: center;
    font-weight: 100;
  }

  .tab-container svg {
    position: absolute;
    right: 2%;
    top: 1%;
  }

  .qb-top-section > img {
    width: 117px;
    height: 111px;
  }

  .status {
    display: flex;
    gap: 9%;
    width: 92%;
  }

  .status p {
    font-family: "Nunito";
    color: white;
    font-size: 20px;
    width: 52%;
    text-align: center;
  }

  /* BOTTOM SECTION  */
  .qb-bottom-section {
    width: 100%;
    height: 428px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .qb-bottom-section > h1 {
    font-size: 25px;
    margin: 0;
    font-family: "Nunito";
    color: #66a3a6;
    font-weight: 400;
  }

  label.qb-label-mv {
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    width: 66%;
  }

  .input-container > input {
    width: 38vw;
    height: 41px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 2.2vw;
    font-family: "Nunito";
    font-weight: 100;
  }

  .qb1-icon-container {
    position: absolute;
    right: 3%;
    transform: translateY(-50%);
    height: 154%;
  }

  .form.quickbook1 {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }

  .next-btn {
    width: 18%;
    height: 5vw;
    font-size: 2.5vw;
    background: #66a3a6;
    border: none;
    border-radius: 10px;
    color: white;
    font-family: "Nunito";
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .qbReContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    height: 100vh;
    width: 100vw;
  }

  .qb-top-section {
    background: url(../assets/png/group.png);
    height: 43%;
    width: auto;
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .imgReLogo {
    width: 108px;
    height: 102px;
  }

  .qb-heading1 {
    color: #fff;
    font-family: Nunito;
    margin-bottom: 0;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .qb-desc {
    display: block;
    width: 61%;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .svgicon {
    margin-top: 2em;
  }

  .status {
    display: flex;
    gap: 12px;
  }

  .status p {
    margin: 0;
    width: 31%;
    text-align: center;
    color: #fff;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  /* BOTTOM SECTION  */

  .qb-bottom-section {
    background-color: #fff;
    height: 57%;
    padding-top: 4vw;
  }

  .qb-bottom-section h1 {
    margin: 0;
    color: #66a3a6;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .qb-underline {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qb-underline img {
    width: 58%;
    height: 10px;
  }

  div.form.quickbook1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6vw;
    padding-top: 14px;
    padding-left: 21px;
  }

  .qb1-icon-container {
    top: 40%;
  }

  .label-input-div {
    width: 100%;
  }

  .input-container {
    width: 92%;
    height: auto;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .label-div {
    width: 86%;
  }

  label.qb-label-mv {
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    width: 66%;
  }

  .input-container > input {
    height: 86%;
    width: 93%;
    border: none;
    font-size: 16px;
  }

  .input-container > input:focus {
    outline: none;
  }

  .btn-container {
    display: flex;
    gap: 3em;
  }

  .back-btn {
    width: 30vw;
    height: 11vw;
    font-size: 5vw;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    background-color: white;
  }

  .next-btn {
    width: 30vw;
    height: 11vw;
    font-size: 5vw;
    border-radius: 10px;
    background: #66a3a6;
    color: #fff;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: none;
  }

  .form-btn {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
