/* .mr-form {
  width: 1377px;
  height: 661px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 4vw 242px 0px 181px;
} */

.mr-form-header {
  width: 1376px;
  height: 82px;
  border-radius: 10px 10px 0px 0px;
  background: #66a3a6;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 79%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.br-input-container {
  width: 1237px;
  height: 430px;
  display: flex;
  gap: 11em;
  padding-top: 58px;
  margin-bottom: -60px;
}

.bc-input-container {
  position: relative;
  display: inline-block;
}

.bc-column1 .label-input-container,
.bc-column2 .label-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.field label {
  color: #000;
  text-align: left;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
}

.field input {
  width: 587px;
  height: 66px;
}

.bc-input-container input {
  width: 503px;
  height: 41px;
  padding-left: 8px;
}

/* .bc-icon-container {
    position: absolute;
    top: 32%;
    right: 28px;
    transform: translateY(-50%);
  } */

.bc-column1,
.bc-column2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bc-column1 label,
.bc-column2 label {
  color: #000;
  font-family: Nunito;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
  opacity: 0.5;
}

/* ICON CONTAINER SVG STYLING    */

.bc1-icon-container {
  position: absolute;
  top: 9px;
  right: 45px;
}

.bc6-icon-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.bc8-icon-container {
  position: absolute;
  top: 47%;
  right: 40px;
  transform: translateY(-50%);
  z-index: 0;
}

.bc3-icon-container,
.bc5-icon-container {
  position: absolute;
  top: 14px;
  right: 42px;
}

/* SUBMIT BUTTON  */

.bc-submit {
  width: 216px;
  height: 61px;
  border-radius: 10px;
  background: #66a3a6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 79%;
  text-transform: capitalize;
  border-style: none;
}

.bc-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.bc-form .dropdown-container {
  position: relative;
  width: 510px;
  height: 41px;
  margin-bottom: 1rem;
}

.bc-form .dropdown-icon-container {
  position: absolute;
  right: 8%;
  top: 33%;
}

.bc-form .dropdown-options {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 508px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.bc-form .selected-room-type {
  width: 497px;
  height: 41px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.5);
}

.error-hidden {
  /* visibility: hidden; */
  width: 167.06px;
  height: 20px;
}

.checkout > label {
  color: #000;
  font-family: Nunito;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 79%;
  text-transform: capitalize;
  opacity: 0.5;
  margin-bottom: 13px;
}

input[type="datetime-local"].bc-checkinout::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 4px;
  right: 36px;
  z-index: 2;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .bc-column1 {
    font-size: 2.9vw;
    width: 50%;
  }

  .label-input-container {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    position: relative;
  }

  .mv-br-input-container {
    display: flex;
    width: 81vw;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .bc-input-container {
    width: 158px;
    height: 32px;
  }

  .bc-input-container input {
    width: 33vw;
    height: 5vw;
    border-radius: 0px;
    font-size: 2.9vw;
    padding-left: 2px;
    padding-top: 2px;
  }

  .bmr1-icon-container {
    position: absolute;
    top: 20%;
    left: 87%;
  }

  .selectedDropdowntype {
    width: 100%;
    height: 100%;
    font-size: 2.9vw;
    padding-left: 2px;
    padding-top: 2px;
  }

  .bc-column1 label,
  .bc-column2 label {
    width: auto;
    font-size: 3vw;
  }
}
