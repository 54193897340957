.coach-content {
  display: flex;
  /* padding-left: 29px; */
  gap: 39px;
  justify-content: flex-end;
  padding: 3rem;
}

.coachDetailsContainer {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

.coachDetails.coach-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 94%;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 90vh;
}

#moduleSeperator {
  position: absolute;
  width: -webkit-fill-available;
  top: 7%;
}

#coachDetailsRatingsSec {
  display: flex;
  gap: 14rem;
  align-items: start;
  justify-content: flex-end;
  padding: 0rem 3rem;
}

.coach-name {
  margin-bottom: auto;
  font-family: Nunito;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.coach-name > span {
  color: #000;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}

.coach-designation {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: unset;
}

.coach-designation .coach-name {
  color: #000;
  font-family: Nunito;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: unset;
}

.skill-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.skill-container > div {
  color: #000000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* font-weight: 600; */
  line-height: normal;
  text-transform: capitalize;
  padding-right: 1rem;
  border-right: 1px solid #000000;
}

.skill-container > div:last-child {
  border: none;
}

.coach-name + span {
  color: #000;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  width: 23vw;
  overflow: auto;
  height: 4vw;
  text-align: center;
}

.credits {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  /* border-left: 1px solid black;
  border-right: 1px solid black; */
  border-radius: 40%;
  padding: 0rem 2rem;
}

.credits > .number {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.coach-name {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.coach-content-right {
  width: 100%;
}

.coach-description {
  width: 80% !important;
  height: max-content !important;
  font-family: Nunito;
  font-size: 22px;
  margin-top: auto;
  font-style: normal;
  font-weight: 300;
  line-height: 42px; /* 227.273% */
  text-transform: capitalize;
  word-break: break-all;
}

.coach-img-btn {
  width: 557px;
  height: 400px;
  /* margin-top: 47px; */
  /* margin-right: 4rem; */
  position: relative;
}

.coach-img-btn > img {
  width: 100%;
  height: 100%;
}

.coach-img-btn button {
  width: 180px;
  height: 63px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #66a3a6;
  color: #fff;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  left: 30% !important;
  bottom: 1.5rem;
}

/* REVIEW SECTION  */

.coach-review-content {
  width: 807px;
  height: 361px;
}

.coach-name {
  color: #66a3a6;
  font-family: Nunito;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.first {
  color: #000;
  font-family: Nunito;
  font-size: 74px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.slash {
  color: rgba(0, 0, 0, 0.5);
  font-family: Nunito;
  font-size: 74px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.total {
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

p.ratings {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.5;
  margin: 0;
}

.second-col {
  display: flex;
}

.coach.mr-root {
  height: 100%;
}

.coach-work-info {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
}

.coach-review {
  border-radius: 50px;
  background: #66a3a6;
  color: white;
  width: 18rem;
  height: 65px;
  border-style: none;
}

.coach-review > span {
  color: #fff;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.coach-personal-info {
  /* margin-left: 16em; */
  /* margin-top: -25em; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.third-col {
  margin-left: 15px;
  margin-bottom: 3rem;
}

.third-col > p {
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
}
.third-col > p > u {
  text-decoration: none;
}

.cd-coach-card-container {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  gap: 6vw;
}

.customer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-details span.cust-name {
  color: #66a3a6;
  text-align: center;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.ratings-content-section {
  display: flex;
  align-items: center;
}

.customer-card-container {
  margin-right: auto;
  display: flex;
  gap: 50px;
}

.coach-details.customer-rating {
  position: absolute;
  top: 81px;
  left: 112px;
}

#coachCustomerRating {
  position: absolute;
  top: 26%;
  left: 35%;
}

.coach-designation span:nth-child(2) {
  box-sizing: border-box;
  /* padding-top: 12px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 6px;
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .coach-picture {
    background-color: white;
    width: 90%;
    height: 75%;
    background-repeat: no-repeat;
    background-size: 100vw;
    height: 0;
    padding-top: 56%;
    margin-top: 4%;
    padding-left: -9%;
    margin-left: 3.5%;
    background-size: contain;
    background-position: center;
  }
  .top-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .coach-image-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
  }
  .coach-image-container > img {
    width: 50%;
    height: 100%;
    border-radius: 6px;
    object-fit: contain;
  }

  .no-average {
    font-size: 28px;
    text-align: center;
  }
  .coachDetails.coach-container {
    gap: 3vw;
  }

  .coach-personal-info {
    margin-top: -2em;
  }

  .coach-designation span:nth-child(2) {
    box-sizing: none;
    padding-top: 0px;
  }

  .subtitle {
    display: flex;
    align-items: baseline;
    gap: 3vw;
    display: flex;
    align-items: baseline;
    padding-left: 4vw;
    margin-bottom: 3px;
  }

  span.name {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 134.7%;
    text-transform: capitalize;
  }
  span.desg {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 134.7%;
    text-transform: capitalize;
  }
  .coach-credits {
    color: #66a3a6;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 79%; /* 12.64px */
    text-transform: capitalize;
    padding-left: 1rem;
  }
  .book-room-btn button.book-coach-btn {
    width: 33vw;
    font-size: 4vw;
    height: 11vw;
    border-radius: 10px;
  }

  .cd-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 26px;
    overflow-y: auto;
  }

  .coach-name-desg {
    display: flex;
    flex-direction: column;
  }

  p.description {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    width: 91vw;
    padding-left: 4vw;
    margin-top: 0;
    margin-bottom: 0;
  }

  #moduleSeperator {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .cd-container {
    position: relative;
    overflow: auto;
    max-height: 92vh;
  }

  .coach-designation .coach-name {
    font-size: 4vw;
  }

  .coach-designation span:nth-child(2) {
    box-sizing: none;
    padding-top: 0px;
  }

  .coachDetails.coach-container {
    gap: 3vw;
  }

  .coach-personal-info {
    margin-top: -2em;
  }

  .noReviewText {
    color: #000;
    font-family: Nunito;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .customer-details span.cust-name {
    color: #66a3a6;
    text-align: center;
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .top-section {
    margin-top: 6px;
  }
  .coach-picture-container {
    display: flex;
    justify-content: center;
  }

  .coach-picture {
    width: 37vw;
    height: 26vw;

    object-fit: cover;
    border-radius: 8px;
  }

  .coach-name {
    color: #000;
    font-weight: 400;
  }

  .coach-name,
  .coach-name + span,
  .credits,
  .credits > .number {
    font-size: 3vw;
  }

  .subtitle + p.description {
    width: 85vw;
    height: auto;
    font-family: Nunito;
    font-size: 2.1vw;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 28px;
    word-wrap: break-word;
    margin-left: 1%;
  }

  .book-room-btn.coach-details {
    position: absolute;
    top: -12%;
  }

  .subtitle {
    margin-left: 1%;
  }

  .third-col > p {
    font-family: Nunito;
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .coach-description {
    width: 46vw;
    height: 16vw;
    line-height: 20px;
  }

  #coachDetailsRatingsSec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 3rem;
    gap: 3rem;
  }
  .coach-content-right > p {
    margin: 0;
    font-family: "Nunito";
    line-height: 20px;
    font-size: 16px;
  }
  .coach-content-right {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 70%;
  }

  .coach-name {
    font-size: 48px;
  }

  .coach-designation span:nth-child(2) {
    box-sizing: none;
    padding-top: 0px;
  }

  .coachDetails.coach-container {
    gap: 3vw;
  }

  .coach-name.coach-details {
    margin-top: 0;
    text-align: center;
  }

  .coach-img-btn {
    width: 25%;
    height: auto;
    margin-top: 30px;
    position: relative;
  }

  .coach-img-btn button {
    width: 12vw;
    height: 3vw;
    border-radius: 10px;
    border: 1px solid #fff;
    background: #66a3a6;
    color: #fff;
    font-family: Nunito;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    position: absolute;
    top: 79%;
    left: 30% !important;
  }

  .coach-designation .coach-name {
    color: #000;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: unset;
  }

  .coach-review-content {
    width: 40vw;
    height: 22vw;
  }
  .coach-review {
    width: max-content;
    height: max-content;
    padding: 0.75rem 1rem;
  }
  .coach-review > span {
    font-size: 18px;
  }

  .coach-name.cd {
    color: #66a3a6;
    font-family: Nunito;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .customer-details span.cust-name {
    font-size: 20px;
  }
  .customer-details:last-child {
    font-size: 16px;
  }
  .coach-work-info {
    font-size: 16px;
  }
}
