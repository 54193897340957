.bookingRequestWrapper {
  padding: 25px 135px 0 75px;
}
.bookingRequestTable {
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid #969191; */
}


.coachReqTableContainer {
  height: 69vh;
}

.coach-requests {
  width: 91vw;
}

.coachReq.noShow {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: Nunito;
  font-weight: 600;
  color: #66a3a6;
}

.coachReq.header {
  font-size: 20px;
  text-decoration: underline;
  font-family: Nunito;
  font-weight: 600;
  color: #66a3a6;
}

.acceptedStatus {
  background-color: green;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}

.rejectedStatus {
  background-color: red;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}

.pendingStatus {
  background-color: orange;
  color: black;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}
.bookingRequestTable th {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  background-color: #66a3a6;
  color: #fff;
  padding: 20px 0;
  border-left: 1px solid #fff;
}
.bookingRequestTable th:first-child {
  border-left: none;
  border-radius: 10px 0 0 0;
}
.bookingRequestTable th:last-child {
  border-radius: 0 10px 0 0;
}
thead {
  font-family: Nunito;
}

.bookingRequestTable td {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 45px;
  border: 1px solid #969191;
  padding: 5px 0px;
}

.bookingRequestTable.coachRequests td {
  padding: 5px 40px;
  max-width: none;
}
table.userApprovalTable td button {
  font-family: "Poppins";
  font-size: 12px;
}
.bookingRequestTable td span.details-span {
  cursor: pointer;
  background: #66a3a6;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  text-transform: capitalize;
}

/* scroll bar*/
/* .bookingRequestTable tbody {
    display: block;
    position: relative;
  } */
.bookingReqTableContainer {
  max-height: 51.54rem;
  /* overflow-y: scroll; */
  position: relative;
}

.mobileViewBookingsDetails {
  display: none;
}
/* .bookingReqTableContainer tbody {
    scrollbar-color: #2980b9 #db343c;
    scrollbar-width: thin;
  }
  .bookingReqTableContainer tbody::-webkit-scrollbar {
    width: 4px;
    height: 5px;
    background-color: #db343c;
  }
  
  .bookingReqTableContainer tbody::-webkit-scrollbar-thumb {
    background-color: #2980b9;
    border-radius: 4px;
  } */
.mobPag {
  display: none;
}
/* scroll bar updated code*/
.bookingReqTableContainer::-webkit-scrollbar {
  border-radius: 0px 0px 9px 0px;
  background: rgba(0, 0, 0, 0.1);
  width: 8px;
}

.bookingReqTableContainer::-webkit-scrollbar-thumb {
  background: #66a3a6;
}

.bookingReqTableContainer::-webkit-scrollbar-button:start {
  display: none;
}

.bookingReqTableContainer::-webkit-scrollbar-button:end {
  display: none;
}
.bookingReqTableContainer {
  scrollbar-color: #66a3a6 rgba(0, 0, 0, 0.1);
  /* scrollbar-width: thin; */
}
.bookingReqTableContainer::-webkit-scrollbar-track-piece {
  width: 15px;
  height: 14px;
}
