.rectangleDiv {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background-color: #66a3a6;
  height: 119px;
  display: flex;
  align-items: center;
}

.frameIcon {
  width: 100px;
  max-width: 100%;
  overflow: hidden;
  height: 115px;
  object-fit: cover;
}

.incubator {
  font-size: 36px;
  line-height: 79%;
  text-transform: capitalize;
  font-family: Nunito;
  color: #fff;
  text-align: center;
  display: inline-block;
}

.rightSide {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1%;
  width: 84%;
}

.left {
  height: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) inset,
    0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 78px;
  display: flex;
  padding: 20px;
}

.gif {
  height: 50px;
}

.today {
  display: flex;
  flex-direction: column;
  gap: 2%;
  justify-content: center;
  font-family: Nunito;
}

.gifIcon {

}
